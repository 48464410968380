<template>
  <b-sidebar
    id="add-new-departamento-sidebar"
    :visible="isAddNewDepartamentoSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-departamento-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Departamento
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Titulo -->
          <validation-provider
            #default="validationContext"
            name="Título"
            rules="required"
          >
            <b-form-group
              label="Titulo"
              label-for="titulo"
            >
              <b-form-input
                id="titulo"
                v-model="departamentoData.titulo"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Titulo"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="Descripcion"
            rules="required"
          >
            <b-form-group
              label="Descripcion"
              label-for="descripcion"
            >
              <b-form-textarea
                id="descripcion"
                v-model="departamentoData.descripcion"
                :state="getValidationState(validationContext)"
                trimplan-filter
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Estado -->
          <validation-provider
            #default="validationContext"
            name="Estado"
            rules="required"
          >
            <b-form-group
              label="Estado"
              label-for="estado"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="departamentoData.estado"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="estadoOptions"
                :clearable="false"
                input-id="estado"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Subdepartamento -->
          <b-form-checkbox
            v-model="isSubDepartamento"
            class="mb-1"
          >
            Es un sub departamento
          </b-form-checkbox>

          <validation-provider
            v-if="isSubDepartamento"
            #default="validationContext"
            name="Subdepartamento de"
            rules="required"
          >
            <b-form-group
              label="Subdepartamento de"
              label-for="parent-id"
            >
              <v-select
                v-model="departamentoData.parent_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="departamentos"
                :clearable="false"
                input-id="parent-id"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormTextarea,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDepartamentoSidebarActive',
    event: 'update:is-add-new-departamento-sidebar-active',
  },
  props: {
    isAddNewDepartamentoSidebarActive: {
      type: Boolean,
      required: true,
    },
    editItem: {
      type: Object,
      required: false,
      default: () => {},
    },
    estadoOptions: {
      type: Array,
      required: true,
    },
    departamentos: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      hideOnEdit: false,
      isSubDepartamento: false,
    }
  },
  watch: {
    editItem() {
      if (this.editItem) {
        this.departamentoData = { ...this.editItem }
        if (this.departamentoData.parent_id && this.departamentoData.parent_id !== 0) {
          this.isSubDepartamento = true
          this.departamentoData.parent_id = this.departamentos.find(el => el.key === this.departamentoData.parent_id)
        }

        this.hideOnEdit = true
        this.departamentoData.estado = this.estadoOptions.find(el => el.value.toString() === this.departamentoData.estado.toString())
      }
    },
  },
  setup(props, { emit }) {
    const blankDepartamentoData = {
      titulo: '',
      description: '',
      estado: '',
      parent_id: 0,
    }
    const departamentoData = ref(JSON.parse(JSON.stringify(blankDepartamentoData)))
    const resetdepartamentoData = () => {
      departamentoData.value = JSON.parse(JSON.stringify(blankDepartamentoData))
    }

    const onSubmit = () => {
      departamentoData.value.estado = departamentoData.value.estado.value
      if (departamentoData.value.parent_id && departamentoData.value.parent_id !== 0) departamentoData.value.parent_id = departamentoData.value.parent_id.key
      else departamentoData.value.parent_id = 0

      store.dispatch('app-departamento/addDepartamento', departamentoData.value).then(() => {
        emit('refetch-data')
        emit('update:is-add-new-departamento-sidebar-active', false)
      })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetdepartamentoData)

    return {
      departamentoData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-departamento-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
